import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Hero from '../components/Hero'
import ContentHero from '../components/ContentHero'
import InformationCard from '../components/InformationCard'

const AboutUsPage = props => {
  const { data } = props
  return (
    <Layout>
      <Hero
        title="Umfirmierung"
        subtitle="aus consileo wird colenio!"
        img={data.hero.childImageSharp}
      />
      <div className="container">
        <ContentHero
          // title="Umfirmierung"
          subtitle="  Im Zuge unserer Unternehmensentwicklung freuen wir uns, mitteilen zu dürfen, dass die consileo GmbH & Co. KG sowie die consileo services GmbH mit Datum vom 27. Okt. 2022 zur
          colenio GmbH & Co. KG bzw. colenio management GmbH umfirmiert haben. Weitere Änderungen ergeben sich aus diesem Schritt nicht. 
          Mit dem neuen Namen colenio verfolgen wir das Ziel, unser Unternehmen mit einer deutlich gestärkten Außen- und Markenwahrnehmung vor allem im wachsenden internationalen Umfeld wirksamer auszurichten.
          
          "
        />
      </div>
    </Layout>
  )
}

AboutUsPage.propTypes = {
  data: PropTypes.shape({
    allAboutUsYaml: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default AboutUsPage

// TODO: Just a placeholder, adjust for specific data
export const pageQuery = graphql`
  query {
    hero: file(relativePath: { eq: "junction.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1920
          aspectRatio: 3.1
          transformOptions: { fit: COVER, cropFocus: CENTER }
        )
      }
    }
  }
`
